import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import * as Sentry from '@sentry/nextjs';
import { NextSeo } from 'next-seo';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {
  Button,
  LinkedinIcon,
  SuperRichTextEditor,
  Typography,
} from '@leaf/ui';
import {
  CurrentWelcomePageDocument,
  CurrentWelcomePageQuery,
  useCurrentInvestorUserQuery,
} from '@/apollo/generated';
import Banner from '@/components/announcements/banner';
import RootLayout from '@/components/layouts/root';
import { BannerHeader } from '@/components/svg/banner-header';
import Redirect from '@/components/utils/redirect';
import Website from '@/components/website';
import EmailSignUp from '@/components/welcome/email-sign-up';
import {
  useCurrentCompany,
  withCurrentCompanyContext,
} from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';
import { useCreateTrackingEvent } from '@/hooks/use-create-tracking-event';
import requireCurrentCompany from '@/utils/require-current-company';
import routes from '@/utils/routes';

interface Props {
  currentWelcomePage?: NonNullable<
    CurrentWelcomePageQuery['currentWelcomePage']
  >;
}

const WelcomePageHeader: React.FC<{
  currentWelcomePage: NonNullable<
    CurrentWelcomePageQuery['currentWelcomePage']
  >;
  isLoggedIn: boolean;
}> = ({ currentWelcomePage, isLoggedIn }) => {
  const { currentCompany, currentCompanyTicker } = useCurrentCompany();
  return (
    <>
      {currentWelcomePage.bannerUrl ? (
        <div className="relative z-0 h-[180px] w-full sm:h-[360px]">
          <Image
            priority
            alt={`${currentCompany.name} investor hub background image`}
            className="z-10"
            layout="fill"
            objectFit="cover"
            quality={100}
            src={currentWelcomePage.bannerUrl}
          />
        </div>
      ) : (
        <div className="relative z-0 h-[360px] w-full">
          <BannerHeader />
        </div>
      )}
      <div className="grid place-items-center">
        <div
          className="mx-4 mt-[-90px] flex min-h-[180px] max-w-[800px] flex-col items-center justify-center gap-6 p-6 sm:mx-6 sm:mt-[-150px] sm:min-h-[250px] sm:px-16 sm:py-14 lg:mx-0"
          style={
            currentCompanyTicker.toLowerCase() !== 'sw1'
              ? {
                  WebkitBackdropFilter: 'blur(80px)',
                  backdropFilter: 'blur(80px)',
                  background:
                    'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgb(0 0 0 / 50%) 0%, rgb(109 109 109 / 50%) 100%)',
                  backgroundBlendMode: 'overlay, normal',
                }
              : {
                  WebkitBackdropFilter: 'blur(100px)',
                  backdropFilter: 'blur(100px)',
                  background:
                    'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgb(0 0 0 / 50%) 0%, rgb(109 109 109 / 50%) 100%)',
                  backgroundBlendMode: 'overlay, normal',
                }
          }
        >
          <Typography className="text-center text-white" variant="large">
            Welcome to the {currentCompany.name} investor community
          </Typography>
          {isLoggedIn ? null : (
            <Link href={routes.auth.signup.href()}>
              <Button>
                <span className="flex items-center gap-2">
                  Join our investor hub <ArrowRightIcon className="h-4 w-4" />
                </span>
              </Button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

const WelcomePageFooter: React.FC = () => {
  const { currentCompany, isUK } = useCurrentCompany();

  return (
    <div className="relative bg-company-primary">
      <div className="relative mx-auto max-w-screen-xl px-4 py-6">
        <div className="flex flex-col gap-6 md:flex-row md:justify-between">
          <div className="grid gap-4 px-1 py-5 md:p-4 lg:p-0 lg:py-20">
            <Typography className="text-company-primary-text" variant="large">
              Join our investor community.
            </Typography>
            <Typography className="text-company-primary-text">
              Never miss an update, access exclusive videos and media, and talk
              directly with the {currentCompany.name} team.
            </Typography>
            <EmailSignUp buttonClassName="!bg-white text-text-main" />
          </div>
          <div className="grid gap-2 px-1 py-5 md:p-4 lg:p-0 lg:py-20">
            <Typography
              className="mb-2 text-company-primary-text md:text-center"
              variant="button"
            >
              WHAT YOU&apos;LL GET
            </Typography>
            <Typography
              className="text-company-primary-text md:text-center"
              variant="small"
            >
              Investor Q&As
            </Typography>
            <Typography className="mb-4 text-company-primary-text md:text-center">
              Ask us a question directly on our interactive announcements.
            </Typography>

            <Typography
              className="text-company-primary-text md:text-center"
              variant="small"
            >
              {isUK ? 'Email alerts' : 'Deal notifications'}
            </Typography>
            <Typography className="text-company-primary-text md:text-center">
              {isUK
                ? 'Receive regulatory news straight to your inbox.'
                : 'Be the first to hear about our future raises.'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const WelcomePage: React.FC<Props> = ({ currentWelcomePage }) => {
  const { data, loading } = useCurrentInvestorUserQuery();
  const { currentCompany, currentCompanyMarketKey, currentCompanyTicker } =
    useCurrentCompany();

  const { currentWebsite } = useWebsite();

  useCreateTrackingEvent({
    event: 'welcomepage_page_viewed',
  });

  if (currentWebsite) {
    return <Website />;
  }

  const renderContent = () => {
    if (currentWelcomePage) {
      return (
        <>
          <WelcomePageHeader
            currentWelcomePage={currentWelcomePage}
            isLoggedIn={!!data?.currentInvestorUser}
          />
          <div className="px-4 py-20 sm:px-6 sm:py-24">
            <div className="mx-auto grid w-full max-w-screen-xl grid-cols-1 gap-8 md:grid-cols-12">
              <div className="col-span-full md:col-span-3">
                {currentWelcomePage.profilePictureUrl ? (
                  <Image
                    alt={currentWelcomePage?.author}
                    className="mb-8"
                    height={240}
                    quality={100}
                    src={currentWelcomePage?.profilePictureUrl}
                    width={240}
                  />
                ) : null}
                <div className="mt-4 border-l-8 border-primary-grey-dark px-4">
                  {currentWelcomePage?.linkedinProfileUrl ? (
                    <a
                      href={currentWelcomePage.linkedinProfileUrl}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <LinkedinIcon className="mb-3 h-4 w-4" />
                    </a>
                  ) : null}
                  <Typography variant="heading-5">
                    {currentWelcomePage?.author}
                  </Typography>
                  {currentWelcomePage?.title ? (
                    <Typography>{currentWelcomePage?.title}</Typography>
                  ) : null}
                  <Typography className="text-text-grey">
                    {currentCompany.name}
                  </Typography>
                </div>
              </div>
              <div className="col-span-full md:col-span-9">
                {currentWelcomePage ? (
                  <SuperRichTextEditor
                    readOnly
                    content={currentWelcomePage.welcomeMessage}
                  />
                ) : null}
                {currentCompanyTicker.toLowerCase() !== 't88' ? (
                  <div className="mt-8">
                    {currentWelcomePage?.signatureUrl ? (
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        alt={`${currentWelcomePage?.author} signature`}
                        className="mb-8 max-w-[240px]"
                        src={currentWelcomePage?.signatureUrl}
                      />
                    ) : null}
                    <Typography className="mb-2">
                      {currentWelcomePage?.author}
                    </Typography>
                    {currentWelcomePage?.title ? (
                      <Typography className="mb-2 text-text-grey">
                        {currentWelcomePage?.title}
                      </Typography>
                    ) : null}
                    <Typography className="text-text-grey">
                      {currentCompany.name}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {!loading && !data?.currentInvestorUser ? (
            <WelcomePageFooter />
          ) : null}
          <Banner hideSignUp />
        </>
      );
    }
  };

  if (!currentWelcomePage) {
    return <Redirect href="/" />;
  }

  return (
    <RootLayout>
      <NextSeo
        description={`We welcome our shareholders and prospective shareholders to join the ${currentCompanyMarketKey}:${currentCompanyTicker} investor hub and engage with the community.`}
        title={`Welcome to the ${currentCompany.name} investor hub!`}
      />
      {renderContent()}
    </RootLayout>
  );
};

export const getServerSideProps = requireCurrentCompany(
  {
    getServerSideProps: async (context, { apolloClient, currentCompany }) => {
      try {
        const { data } = await apolloClient.query<CurrentWelcomePageQuery>({
          query: CurrentWelcomePageDocument,
          variables: {
            listingKey: currentCompany.ticker.listingKey,
            marketKey: currentCompany.ticker.marketKey,
          },
        });

        if (data.currentWelcomePage) {
          return {
            props: {
              currentWelcomePage: data.currentWelcomePage,
            },
          };
        }

        const hasPublishedWebsite = !!currentCompany.publishedWebsite;
        const hasCurrentWebsitePage =
          !!currentCompany.publishedWebsite?.currentPage;

        if (hasPublishedWebsite && !hasCurrentWebsitePage) {
          // This is the hubs 2.0 404 page
          context.res.statusCode = 404;
        } else if (!hasPublishedWebsite) {
          // This is the hubs 1.0 404 page
          context.res.statusCode = 404;
        }
        return {
          props: {},
        };
      } catch (e) {
        Sentry.captureException(e);
        return {
          props: {},
        };
      }
    },
  },
  'welcome'
);

export default withCurrentCompanyContext(WelcomePage);
