import React from 'react';
import analytics from '@analytics';
import { ArrowRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { Button, TextInput } from '@leaf/ui';
import routes from '@/utils/routes';

interface Props {
  buttonClassName?: string;
}

const EmailSignUp: React.FC<Props> = ({ buttonClassName }) => {
  const [email, setEmail] = React.useState('');
  return (
    <div className="flex w-full max-w-[480px] flex-col items-center gap-2 md:flex-row">
      <div className="w-full flex-1">
        <TextInput
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <Link
        className="w-full flex-1 md:w-auto md:flex-none"
        href={routes.auth.signup.href(email)}
        onClick={() => analytics.track('signup_with_email_input_clicked')}
      >
        <Button
          className={clsx(
            'w-full flex-1 md:w-auto md:flex-none',
            buttonClassName
          )}
        >
          <span className="flex items-center gap-2 text-text-main">
            Sign Up <ArrowRightIcon className="h-4 w-4" />
          </span>
        </Button>
      </Link>
    </div>
  );
};

export default EmailSignUp;
